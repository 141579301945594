const LEGACY_CATEGORIES: Record<string, any> = {
  '1': {
    criteria: 'PRICE',
    description: '',
    enabled: true,
    id: 1,
    name: 'Laptops',
    originalPackagingSelectable: true,
    seoUrl: 'laptops',
    translations: {
      EN: {
        description: '',
        name: 'Laptops',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Laptops',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_symbolLaptop-3oRNYou',
  },
  '2': {
    criteria: 'VOLUME',
    description: '',
    enabled: true,
    id: 2,
    name: 'Computer Parts',
    originalPackagingSelectable: true,
    seoUrl: 'computer-parts',
    translations: {
      EN: {
        description: '',
        name: 'Computer Parts',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Partes de PC',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [
      {
        code: 'S',
        enabled: true,
        estimatedWeightLbs: '2.20',
        label: 'Processors',
        translations: {
          EN: {
            description: null,
            label: 'Processors',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Procesadores',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'M',
        enabled: true,
        estimatedWeightLbs: '6.60',
        label: 'Graphic cards',
        translations: {
          EN: {
            description: null,
            label: 'Graphic cards',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Tarjetas de video',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'L',
        enabled: true,
        estimatedWeightLbs: '8.80',
        label: 'Motherboards',
        translations: {
          EN: {
            description: null,
            label: 'Motherboards',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Placas madre',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
    ],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '3': {
    criteria: 'PRICE',
    description: '',
    enabled: true,
    id: 3,
    name: 'Phones',
    originalPackagingSelectable: true,
    seoUrl: 'phones',
    translations: {
      EN: {
        description: '',
        name: 'Phones',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Celulares',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_symbolPhone-BNxig9J',
  },
  '4': {
    criteria: 'VOLUME',
    description: '',
    enabled: true,
    id: 4,
    name: 'Cameras & Camcorders',
    originalPackagingSelectable: true,
    seoUrl: 'cameras-camcorders',
    translations: {
      EN: {
        description: '',
        name: 'Cameras & Camcorders',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Cámaras y Video',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [
      {
        code: 'XS',
        enabled: true,
        estimatedWeightLbs: '5.00',
        label: 'GoPro',
        translations: {
          EN: {
            description: '3 cm x 5 cm x 7 cm',
            label: 'GoPro',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: '3 cm x 5 cm x 7 cm',
            label: 'GoPro',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'S',
        enabled: true,
        estimatedWeightLbs: '5.00',
        label: 'Compact',
        translations: {
          EN: {
            description: '10 cm x 5 cm x 2 cm',
            label: 'Compact',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: '10 cm x 5 cm x 2 cm',
            label: 'Compact',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'M',
        enabled: true,
        estimatedWeightLbs: '10.00',
        label: 'DSLR',
        translations: {
          EN: {
            description: '13 cm x  10 cm x 7 cm',
            label: 'DSLR',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: '13 cm x  10 cm x 7 cm',
            label: 'DSLR',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'L',
        enabled: true,
        estimatedWeightLbs: '30.00',
        label: 'Camcorder',
        translations: {
          EN: {
            description: '22 cm x 15 cm x 10 cm',
            label: 'Camcorder',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: '22 cm x 15 cm x 10 cm',
            label: 'Filmadora',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
    ],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '5': {
    criteria: 'WEIGHT',
    description: '',
    enabled: false,
    id: 5,
    name: 'Electronics',
    originalPackagingSelectable: true,
    seoUrl: 'electronics',
    translations: {
      EN: {
        description: '',
        name: 'Electronics',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Electronicos',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '6': {
    criteria: 'WEIGHT',
    description: '',
    enabled: true,
    id: 6,
    name: 'Health & Supplements',
    originalPackagingSelectable: false,
    seoUrl: 'health-supplements',
    translations: {
      EN: {
        description: '',
        name: 'Health & Supplements',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Salud y Suplementos',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '7': {
    criteria: 'WEIGHT',
    description: '',
    enabled: true,
    id: 7,
    name: 'Toys',
    originalPackagingSelectable: true,
    seoUrl: 'toys',
    translations: {
      EN: {
        description: '',
        name: 'Toys & Games',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Juegos & Juguetes',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '8': {
    criteria: 'WEIGHT',
    description: '',
    enabled: false,
    id: 8,
    name: 'Clothing',
    originalPackagingSelectable: false,
    seoUrl: 'clothing',
    translations: {
      EN: {
        description: '',
        name: 'Clothing',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Ropa',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '9': {
    criteria: 'WEIGHT',
    description: '',
    enabled: true,
    id: 9,
    name: 'Other',
    originalPackagingSelectable: true,
    seoUrl: 'other',
    translations: {
      EN: {
        description: '',
        name: 'Other',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Otros',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '10': {
    criteria: 'WEIGHT',
    description: '',
    enabled: false,
    id: 10,
    name: 'Electronic Accessories',
    originalPackagingSelectable: true,
    seoUrl: 'electronic-accessories',
    translations: {
      EN: {
        description: '',
        name: 'Electronic Accessories',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Accesorios de Electrónicos',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '11': {
    criteria: 'VOLUME',
    description: '',
    enabled: true,
    id: 11,
    name: 'Tablets',
    originalPackagingSelectable: true,
    seoUrl: 'tablets',
    translations: {
      EN: {
        description: '',
        name: 'Tablets',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Tabletas',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [
      {
        code: 'M',
        enabled: true,
        estimatedWeightLbs: null,
        label: 'Less than 13"',
        translations: {
          EN: {
            description: null,
            label: '13" or Less',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: '13" o menos',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'L',
        enabled: true,
        estimatedWeightLbs: null,
        label: 'Greater than 13"',
        translations: {
          EN: {
            description: null,
            label: 'Greater than 13"',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Mayor a 13"',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
    ],
    icon: 'qempo_icons_symbolTablet-2bFPopj',
  },
  '12': {
    criteria: 'VOLUME',
    description: '',
    enabled: true,
    id: 12,
    name: 'Shoes',
    originalPackagingSelectable: true,
    seoUrl: 'shoes',
    translations: {
      EN: {
        description: '',
        name: 'Shoes',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Calzado',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [
      {
        code: 'XS',
        enabled: true,
        estimatedWeightLbs: '2.00',
        label: 'Kid shoes',
        translations: {
          EN: {
            description: null,
            label: 'Kid shoes',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Zapatos de niño',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'S',
        enabled: true,
        estimatedWeightLbs: '2.00',
        label: 'Sandals',
        translations: {
          EN: {
            description: null,
            label: 'Sandals',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Sandalias',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'M',
        enabled: true,
        estimatedWeightLbs: '4.00',
        label: 'Shoes',
        translations: {
          EN: {
            description: null,
            label: 'Shoes',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Zapatos',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'L',
        enabled: true,
        estimatedWeightLbs: '12.00',
        label: 'Boots',
        translations: {
          EN: {
            description: null,
            label: 'Boots',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Botas',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
    ],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '13': {
    criteria: 'WEIGHT',
    description: '',
    enabled: true,
    id: 13,
    name: 'Makeup',
    originalPackagingSelectable: false,
    seoUrl: 'makeup',
    translations: {
      EN: {
        description: '',
        name: 'Makeup',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Maquillaje',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '14': {
    criteria: 'WEIGHT',
    description: '',
    enabled: true,
    id: 14,
    name: 'Perfume & Lotions',
    originalPackagingSelectable: false,
    seoUrl: 'perfume-lotions',
    translations: {
      EN: {
        description: '',
        name: 'Perfume & Lotions',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Perfumes y Cremas',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '15': {
    criteria: 'WEIGHT',
    description: '',
    enabled: false,
    id: 15,
    name: 'Clothing Accessories',
    originalPackagingSelectable: false,
    seoUrl: 'fashion-accessories',
    translations: {
      EN: {
        description: '',
        name: 'Clothing Accessories',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Accesorios de Vestir',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_iconHappyFaceMedium-nnFWtuu',
  },
  '16': {
    criteria: 'VOLUME',
    description: '',
    enabled: true,
    id: 16,
    name: 'Game Consoles',
    originalPackagingSelectable: true,
    seoUrl: 'game-consoles',
    translations: {
      EN: {
        description: '',
        name: 'Game Consoles',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Consolas de Juegos',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [
      {
        code: 'S',
        enabled: true,
        estimatedWeightLbs: '4.00',
        label: 'Handheld',
        translations: {
          EN: {
            description: null,
            label: 'Handheld',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Consola Portátil',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'M',
        enabled: true,
        estimatedWeightLbs: '8.00',
        label: 'Nintendo Switch',
        translations: {
          EN: {
            description: null,
            label: 'Nintendo Switch',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Nintendo Switch',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'L',
        enabled: true,
        estimatedWeightLbs: '23.00',
        label: 'PS5/PS4/Xbox/Oculus',
        translations: {
          EN: {
            description: null,
            label: 'PS4/PS5/Xbox/Oculus',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'PS4/PS5/Xbox/Oculus',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
    ],
    icon: 'qempo_icons_symbolGame-2i80A8O',
  },
  '17': {
    criteria: 'WEIGHT',
    description: '',
    enabled: true,
    id: 17,
    name: 'Auto Parts',
    originalPackagingSelectable: true,
    seoUrl: 'auto-parts',
    translations: {
      EN: {
        description: '',
        name: 'Auto Parts',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Autopartes',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
    icon: 'qempo_icons_symbolLaptop-3oRNYou',
  },
  '18': {
    criteria: 'PRICE',
    description: '',
    enabled: true,
    id: 18,
    name: 'e-Reader',
    originalPackagingSelectable: true,
    seoUrl: 'e-reader',
    translations: {
      EN: {
        description: '',
        name: 'e-Reader',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'e-Reader',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
  },
  '19': {
    criteria: 'PRICE',
    description: '',
    enabled: true,
    id: 19,
    name: 'Smartwatches',
    originalPackagingSelectable: true,
    seoUrl: 'smartwatches',
    translations: {
      EN: {
        description: '',
        name: 'Smartwatches',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Smartwatches',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
  },
  '20': {
    criteria: 'WEIGHT',
    description: '',
    enabled: true,
    id: 20,
    name: 'Drones',
    originalPackagingSelectable: true,
    seoUrl: 'drones',
    translations: {
      EN: {
        description: '',
        name: 'Drones',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Drones',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
  },
  '21': {
    criteria: 'PRICE',
    description: '',
    enabled: true,
    id: 21,
    name: 'Watches',
    originalPackagingSelectable: true,
    seoUrl: 'watches',
    translations: {
      EN: {
        description: '',
        name: 'Watches',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Relojes',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
  },
  '22': {
    criteria: 'VOLUME',
    description: '',
    enabled: true,
    id: 22,
    name: 'Headphones',
    originalPackagingSelectable: true,
    seoUrl: 'headphones',
    translations: {
      EN: {
        description: '',
        name: 'Headphones',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Audífonos',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [
      {
        code: 'XS',
        enabled: true,
        estimatedWeightLbs: '2.20',
        label: 'Earphones',
        translations: {
          EN: {
            description: null,
            label: 'Earphones',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Auriculares',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
      {
        code: 'S',
        enabled: true,
        estimatedWeightLbs: '4.40',
        label: 'Headphones',
        translations: {
          EN: {
            description: null,
            label: 'Headphones',
            type: 'item-category-volume-tier-translation',
          },
          ES: {
            description: null,
            label: 'Audífonos',
            type: 'item-category-volume-tier-translation',
          },
        },
        type: 'item-category-volume-tier',
      },
    ],
  },
  '23': {
    criteria: 'PRICE',
    description: '',
    enabled: true,
    id: 23,
    name: 'Oculus/VR',
    originalPackagingSelectable: true,
    seoUrl: 'oculus-vr',
    translations: {
      EN: {
        description: '',
        name: 'Vision Pro/Oculus/VR Headset',
        type: 'item-category-translation',
      },
      ES: {
        description: '',
        name: 'Vision Pro/Oculus/Casco VR',
        type: 'item-category-translation',
      },
    },
    type: 'item-category',
    volumeTiers: [],
  },
};

export function getLegacyCategory(legacyCategoryId: number | string) {
  const id =
    typeof legacyCategoryId === 'number'
      ? `${legacyCategoryId}`
      : legacyCategoryId;
  return LEGACY_CATEGORIES[id];
}
