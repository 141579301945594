import {AxiosInstance} from 'axios';
import {axiosInstance} from '@refinedev/simple-rest';
import {DataProvider} from '@refinedev/core';
import {ExchangeRateService} from './service';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'getList'
  | 'create'
  | 'deleteOne'
  | 'getMany'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const exchangeRateService = new ExchangeRateService(httpClient);

  return {
    update: async ({variables}) => {
      const {value} = variables as {value: number};

      const exchangeRate = await exchangeRateService.saveExchangeRate({
        currencyFrom: 'USD',
        currencyTo: 'PEN',
        value: value.toFixed(2),
      });

      return {
        data: exchangeRate,
      };
    },
    getOne: async () => {
      const exchangeRate = await exchangeRateService.getExchangeRate({
        currencyFrom: 'USD',
        currencyTo: 'PEN',
      });

      return {
        data: exchangeRate,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
