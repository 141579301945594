import {IResourceComponentsProps, useShow, useUpdate} from '@refinedev/core';
import {IExchangeRate} from '../../../interfaces';
import React, {useEffect, useState} from 'react';
import {Show} from '@refinedev/antd';
import {Button, Card, Col, InputNumber, Row, Statistic, Typography} from 'antd';
import {SaveOutlined} from '@ant-design/icons';

const {Paragraph} = Typography;

import usa from '../../../assets/img/flags/usa.svg';
import peru from '../../../assets/img/flags/peru.svg';

export const ExchangeRateShow: React.FC<IResourceComponentsProps> = () => {
  const {queryResult: exchangeRateQueryResult} = useShow<IExchangeRate>({
    resource: 'exchangeRates',
    id: 'USD-PEN',
  });

  const {mutate, isLoading: isSaving} = useUpdate({
    resource: 'exchangeRates',
  });

  const {isLoading, data} = exchangeRateQueryResult;

  const [value, setValue] = useState<number>();

  useEffect(() => {
    if (data?.data.value) {
      const v = parseFloat(data?.data.value);
      setValue(v);
    }
  }, [data?.data]);

  const onSave = () => {
    mutate({
      id: 'USD-PEN',
      values: {
        value,
      },
    });
  };

  return (
    <Show isLoading={isLoading} headerButtons={() => null}>
      <Row gutter={16} justify="center">
        <Col xs={24} sm={24} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Currency From"
              value="USD"
              prefix={<img src={usa} alt="USD" width="30px" height="30px" />}
              suffix="$"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Card bordered={false}>
            <Statistic
              title="Currency To"
              value="PEN"
              prefix={<img src={peru} alt="USD" width="30px" height="30px" />}
              suffix="S/"
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16} justify="center">
        <Col xs={24} sm={24} md={10}>
          <InputNumber
            value={value}
            size="large"
            style={{width: '100%'}}
            precision={2}
            step={0.1}
            onChange={(v) => setValue(v as number)}
            disabled={isLoading || isSaving}
          />
          <Paragraph>
            <blockquote>Last updated: {data?.data.updatedAt}</blockquote>
          </Paragraph>
        </Col>
        <Col xs={24} sm={24} md={2}>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            size="large"
            disabled={isLoading || isSaving}
            onClick={onSave}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Show>
  );
};
