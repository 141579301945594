import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';
import {LegacyOrder} from '../../interfaces';

export class LegacyService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getOrder(legacyOrderId: string): Promise<LegacyOrder> {
    const url = `${vars.baseApiUrl}/logistics/legacy/order/${legacyOrderId}`;

    const {data} = await this.httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async payOrder(legacyOrderId: string): Promise<LegacyOrder> {
    const url = `${vars.baseApiUrl}/logistics/legacy/order/${legacyOrderId}/pay`;

    const {data} = await this.httpClient.post(url, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async sendPaidOrderMail(legacyOrderId: string): Promise<void> {
    const url = `${vars.baseApiUrl}/logistics/legacy/order/${legacyOrderId}/send-mail/paid`;

    await this.httpClient.post(url, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
  }
}
