import React from 'react';
import {Tag} from 'antd';
import {IOrderServiceType} from '../../../interfaces';

export const OrderServiceType: React.FC<{
  serviceType?: IOrderServiceType;
}> = ({serviceType}) => {
  let color = 'cyan';

  if (serviceType === 'LITE') color = 'gold';
  if (serviceType === 'FULL') color = 'magenta';

  return <Tag color={color}>{serviceType}</Tag>;
};
