import React from 'react';
import {Authenticated, DataProvider, Refine} from '@refinedev/core';
import {RefineKbar, RefineKbarProvider} from '@refinedev/kbar';
import {useNotificationProvider, ThemedLayoutV2} from '@refinedev/antd';
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import dataProvider from '@refinedev/simple-rest';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import {authProvider} from './authProvider';
import {ColorModeContextProvider} from './layout/contexts/color-mode';
import {vars} from './vars';
import {AuthPage} from './modules/auth/pages/Login';
import {Emoji, Header, Title} from './layout/components';
import {
  CourierCreate,
  CourierList,
  CourierShow,
  CourierEdit,
} from './modules/courier/pages';
import {dataProvider as courierDataProvider} from './modules/courier/dataProvider';
import {dataProvider as userDataProvider} from './modules/user/dataProvider';
import {dataProvider as orderDataProvider} from './modules/order/dataProvider';
import {dataProvider as orderItemTrackingProvider} from './modules/order-item-tracking/dataProvider';
import {dataProvider as orderItemProvider} from './modules/order-item/dataProvider';
import {dataProvider as orderShippingAddressProvider} from './modules/order-shipping-address/dataProvider';
import {dataProvider as payoutDataProvider} from './modules/payout/dataProvider';
import {dataProvider as categoryDataProvider} from './modules/category/dataProvider';
import {dataProvider as exchangeRateDataProvider} from './modules/exchange-rate/dataProvider';
import {OrderCreate, OrderList, OrderShow} from './modules/order/pages';
import {PayoutList} from './modules/payout/pages';
import {WelcomePage} from 'modules/welcome/pages';
import {UserList, UserCreate, UserEdit} from './modules/user/pages';

import '@refinedev/antd/dist/reset.css';
import './main.scss';
import {ExchangeRateShow} from './modules/exchange-rate/pages/show';
import {LegacyOrderShow} from './modules/legacy/pages/order/show';

function App() {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={{
              default: dataProvider(vars.baseApiUrl),
              courier: courierDataProvider(vars.baseApiUrl) as DataProvider,
              user: userDataProvider(vars.baseApiUrl) as DataProvider,
              order: orderDataProvider(vars.baseApiUrl) as DataProvider,
              orderItemTracking: orderItemTrackingProvider(
                vars.baseApiUrl
              ) as DataProvider,
              orderItem: orderItemProvider(vars.baseApiUrl) as DataProvider,
              orderShippingAddress: orderShippingAddressProvider(
                vars.baseApiUrl
              ) as DataProvider,
              payout: payoutDataProvider(vars.baseApiUrl) as DataProvider,
              category: categoryDataProvider(vars.baseApiUrl) as DataProvider,
              exchangeRate: exchangeRateDataProvider(
                vars.baseApiUrl
              ) as DataProvider,
            }}
            notificationProvider={useNotificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
            }}
            resources={[
              {
                name: 'couriers',
                list: '/couriers',
                create: '/couriers/create',
                edit: '/couriers/edit/:id',
                show: '/couriers/show/:id',
                meta: {
                  icon: (
                    <Emoji size={20} emoji="📦" style={{marginRight: 24}} />
                  ),
                  dataProviderName: 'courier',
                },
              },
              {
                name: 'users',
                list: '/users',
                create: '/users/create',
                edit: '/users/edit/:id',
                show: '/users/show/:id',
                meta: {
                  icon: (
                    <Emoji size={20} emoji="👤" style={{marginRight: 24}} />
                  ),
                  dataProviderName: 'user',
                },
              },
              {
                name: 'orders',
                list: '/orders',
                create: '/orders/create',
                show: '/orders/show/:id',
                meta: {
                  icon: (
                    <Emoji size={20} emoji="🛒" style={{marginRight: 24}} />
                  ),
                  dataProviderName: 'order',
                },
              },
              {
                name: 'orderItemTrackings',
                meta: {
                  dataProviderName: 'orderItemTracking',
                },
              },
              {
                name: 'orderItems',
                meta: {
                  dataProviderName: 'orderItem',
                },
              },
              {
                name: 'orderShippingAddresses',
                meta: {
                  dataProviderName: 'orderShippingAddress',
                },
              },
              {
                name: 'payouts',
                list: '/payouts',
                meta: {
                  icon: (
                    <Emoji size={20} emoji="💵" style={{marginRight: 24}} />
                  ),
                  dataProviderName: 'payout',
                },
              },
              {
                name: 'categories',
                meta: {
                  dataProviderName: 'category',
                },
              },
              {
                name: 'exchangeRates',
                list: '/exchange-rates/show',
                // show: '/exchange-rates/show',
                meta: {
                  icon: (
                    <Emoji size={20} emoji="💱" style={{marginRight: 24}} />
                  ),
                  dataProviderName: 'exchangeRate',
                },
              },
              {
                name: 'legacy',
                list: '/legacy/show',
                meta: {
                  label: 'Legacy',
                  icon: (
                    <Emoji size={20} emoji="👴🏼" style={{marginRight: 24}} />
                  ),
                },
              },
            ]}
          >
            <Routes>
              <Route index element={<WelcomePage />} />

              <Route
                element={
                  <Authenticated
                    key="authenticated-all"
                    v3LegacyAuthProviderCompatible
                    fallback={<CatchAllNavigate to="/login" />}
                  >
                    <ThemedLayoutV2 Header={Header} Title={Title}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="/couriers">
                  <Route index element={<CourierList />} />
                  <Route path="create" element={<CourierCreate />} />
                  <Route path="edit/:id" element={<CourierEdit />} />
                  <Route path="show/:id" element={<CourierShow />} />
                </Route>

                <Route path="/users">
                  <Route index element={<UserList />} />
                  <Route path="create" element={<UserCreate />} />
                  <Route path="edit/:id" element={<UserEdit />} />
                  {/*<Route path="show/:id" element={<CourierShow />} />*/}
                </Route>

                <Route path="/orders">
                  <Route index element={<OrderList />} />
                  <Route path="create" element={<OrderCreate />} />
                  <Route path="show/:id" element={<OrderShow />} />
                </Route>

                <Route path="/payouts">
                  <Route index element={<PayoutList />} />
                </Route>

                <Route path="/exchange-rates">
                  <Route path="show" element={<ExchangeRateShow />} />
                </Route>

                <Route path="/legacy">
                  <Route path="show" element={<LegacyOrderShow />} />
                </Route>
              </Route>

              <Route
                element={
                  <Authenticated
                    key="authenticated-login"
                    v3LegacyAuthProviderCompatible
                    fallback={<Outlet />}
                  >
                    <NavigateToResource resource="orders" />
                  </Authenticated>
                }
              >
                <Route
                  path="/login"
                  element={
                    <AuthPage
                      type="login"
                      registerLink={<></>}
                      forgotPasswordLink={<></>}
                      formProps={{
                        initialValues: {
                          email:
                            vars.environment !== 'production'
                              ? 'admin@qempo.com'
                              : '',
                          password:
                            vars.environment !== 'production' ? 'qempo123' : '',
                        },
                      }}
                    />
                  }
                />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
