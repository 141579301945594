import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';

export type GetExchangeRate = {
  currencyFrom: string;
  currencyTo: string;
};

export type SaveExchangeRate = {
  currencyFrom: string;
  currencyTo: string;
  value: string;
};

export class ExchangeRateService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getExchangeRate(request: GetExchangeRate) {
    const url = `${vars.baseApiUrl}/logistics/admin/exchange-rate/?currency_from=${request.currencyFrom}&currency_to=${request.currencyTo}`;

    const {data} = await this.httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async saveExchangeRate(request: SaveExchangeRate) {
    const url = `${vars.baseApiUrl}/logistics/admin/exchange-rate/`;

    const {data} = await this.httpClient.post(url, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }
}
